<template>
    <div>
        <web-header />
        <div class="container">

  

            <form action="" class="from-horizontal">
                <div>
                    <div class="form-group left">
                        <label for="left" class=" control-label col-sm-2">Nombre</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" name="nombre" id="nombre" v-model="form.nombre">
                        </div>
                    </div>

                    <div class="form-group left">
                        <label for="left" class=" control-label col-sm-2">Direccion</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" name="direccion" id="direccion"
                                v-model="form.direccion">
                        </div>
                    </div>

                    <div class="form-group left">
                        <label for="" class=" control-label col-sm-2">DNI</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" name="DNI" id="DNI" v-model="form.dni">
                        </div>
                    </div>

                    <div class="form-group left">
                        <label for="" class=" control-label col-sm-1">Correo</label>
                        <div class="col-sm-3">
                            <input type="text" class="form-control" name="correo" id="correo" v-model="form.correo">
                        </div>
                    </div>

                    <div class="form-group left">
                        <label for="" class=" control-label col-sm-2">Codigo Postal</label>
                        <div class="col-sm-3">
                            <input type="text" class="form-control" name="codigoPostal" id="codigoPostal"
                                v-model="form.codigoPostal">
                        </div>
                    </div>

                    <div class="form-group left">
                        <label for="left" class=" control-label col-sm-2">Genero</label>
                        <div class="col-sm-3">
                            <input type="text" class="form-control" name="genero" id="genero" v-model="form.genero">
                        </div>
                    </div>

                    <div class="form-group left">
                        <label for="" class=" control-label col-sm-2">Telefono</label>
                        <div class="col-sm-3">
                            <input type="text" class="form-control" name="telefono" id="telefono"
                                v-model="form.telefono">
                        </div>
                    </div>

                    <div class="form-group left">
                        <label for="" class=" control-label col-sm-2">Fecha Nacimiento</label>
                        <div class="col-sm-3">
                            <input type="text" class="form-control" name="fechaNacimiento" id="fechaNacimiento"
                                v-model="form.fechaNacimiento">
                        </div>
                    </div>

                    <div class="form-group">
                        <button type="button" class="btn btn-primary" v-on:click="editar()">Editar</button>
                        <button type="button" class="btn btn-danger margen" v-on:click="eliminar()">Eliminar</button>
                        <button type="button" class="btn btn-dark margen" v-on:click="salir()">Salir</button>

                    </div>


                </div>
            </form>
        </div>

        <web-footer />
    </div>

</template>
<script>
import WebFooter from '@/components/WebFooter.vue';
import WebHeader from '@/components/WebHeader.vue';
import axios from 'axios';

export default {
    name: "EditarPaciente",
    data: function () {
        return {
            form: {
                "pacienteId": "",
                "nombre": "",
                "direccion": "",
                "dni": "",
                "correo": "",
                "codigoPostal": "",
                "genero": "",
                "telefono": "",
                "fechaNacimiento": "",
                "token": ""
            }
        }
    },
    components: {
        WebHeader,
        WebFooter
    },
    methods: {
        editar() {
            axios.put("http://localhost/primera-rest-api/pacientes", this.form)
                .then(data => {
                    console.log(data);
                })
        },
        salir() {
            this.$router.push("/userdashboard");
        },
        eliminar() {
            var enviar = {
                "pacienteId": this.form.pacienteId,
                "token": this.form.token
            };
            axios.delete("http://localhost/primera-rest-api/pacientes", { headers: enviar })
                .then(datos => {
                    console.log(datos);
                    this.$router.push("/userdashboard");
                })
        }

    },
    mounted: function () {
        this.form.pacienteId = this.$route.params.id;
        axios.get("http://localhost/primera-rest-api/pacientes?id=" + this.form.pacienteId)
            .then(datos => {
                this.form.nombre = datos.data[0].Nombre;
                this.form.nombre = datos.data[0].Nombre;
                this.form.direccion = datos.data[0].Direccion;
                this.form.dni = datos.data[0].DNI;
                this.form.correo = datos.data[0].Correo;
                this.form.codigoPostal = datos.data[0].CodigoPostal;
                this.form.genero = datos.data[0].Genero;
                this.form.telefono = datos.data[0].Telefono;
                this.form.fechaNacimiento = datos.data[0].FechaNacimiento;
                this.form.token = localStorage.getItem("token");

                console.log(this.form);
            })
    }
}
</script>

<style scoped>
.left {
    text-align: left;
}
</style>
