<template>
    <div>
        <WebHeader />

        <div class="container izquierda">
            <button class="btn btn-primary" v-on:click="nuevo()">Nuevo Paciente</button>
            <br><br>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">DNI</th>
                        <th scope="col">Telefono</th>
                        <th scope="col">Correo E-mail</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="paciente in Listapacientes" :key="paciente.PacienteId" v-on:click="editar(paciente.PacienteId)">
                        <th scope="row">{{paciente.PacienteId}}</th>
                        <td>{{paciente.Nombre}}</td>
                        <td>{{paciente.DNI}}</td>
                        <td>{{paciente.Telefono}}</td>
                        <td>{{paciente.Correo}}</td>
                    </tr>
                
                </tbody>
            </table>
        </div>

        <!-- <WebFooter /> -->
    </div>

</template>

<script>
// import WebFooter from '@/components/WebFooter.vue';
import WebHeader from '@/components/WebHeader.vue';
import axios from 'axios';
export default {
    name: "UserDashboard",
    data(){
        return{
            Listapacientes:null,
            pagina:1
        }
    },
    components: {
        WebHeader,
        // WebFooter
    },
    methods:{
        editar(id){
            this.$router.push('/editarpaciente/' + id);
        },
        nuevo(){
            this.$router.push('/nuevopaciente');
        }


    },
    mounted:function(){
        let direccion = "http://localhost/primera-rest-api/pacientes?page=" + this.pagina;
        axios.get(direccion).then ( data => {
            this.Listapacientes = data.data;
        } )
    }
}

</script>


<style>
.izquierda{
    text-align: left;
}



</style>