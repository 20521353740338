import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UserDashboard from '../views/UserDashboard.vue'
import EditarPaciente from '../views/EditarPaciente.vue'
import NuevoPaciente from '@/views/NuevoPaciente.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/userdashboard',
    name: 'userdashboard',
    component: UserDashboard
  },
  {
    path: '/editarpaciente/:id',
    name: 'editarpaciente',
    component: EditarPaciente
  },
  {
    path: '/nuevopaciente',
    name: 'nuevopaciente',
    component: NuevoPaciente
  }
  


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
